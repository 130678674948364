<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      :title="`Block games to user ${user.username}`"
      hide-footer
    >
      <b-card
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 300px; overflow-y: scroll"
        no-body
      >
        <b-list-group v-if="!productSelectId">
          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Select a product to see the games</span>

            <div>Games</div>
          </b-list-group-item>
           <div class="m-2 text-center" v-if="productsOptions && !productsOptions.length >= 1">
                <h4>The player has no games</h4>
            </div>

      
          <b-list-group-item
           v-else
            v-for="(tr, index) in productsOptions"
            :key="index"
            class="d-flex justify-content-between align-items-center cursor-pointer"
            @click="selectProduct(tr._id, tr.name)"
          >
          <!-- <b-overlay :show="tr.loading" variant="transparent" no-wrap /> -->
            <span>{{ tr.name }}</span>

            <div>
              <b-badge
                pill
                :variant="`light-${tr.isgame ? 'secondary' : 'primary'}`"
                class="text-capitalize"
              >
                {{
                  tr.isgame
                    ? "Product game"
                    : `${tr.gamesTotal} games`
                }}
              </b-badge>
            </div>

            <!-- <b-form-checkbox
              :checked="tr.status"
              v-model="tr.status"
              class="custom-control-danger"
              name="check-button"
              switch
              @change="blockGame(tr.gameId, tr.status, tr)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="LockIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="UnlockIcon" />
              </span>
            </b-form-checkbox> -->

          </b-list-group-item>
        </b-list-group>


        <!-- cuando se tiene seleccionado un producto se puede ver la lista de juegos -->
         <b-list-group v-if="productSelectId">
           <b-overlay :show="loadingDataGames" variant="transparent" no-wrap />
           <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
          <div>
            <b-button
              variant="light"
              class="mr-1 btn-icon rounded-circle"
              size="sm"
              @click="productSelectId = ''"
            >
              <feather-icon icon="ArrowLeftIcon"/>
            </b-button>
            <span class="text-bold">{{productName}} games </span>
          </div>

            <div class="mr-1">Status</div>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
          <!-- searh -->
            <b-form-input
              v-model="searchGames"
              placeholder="Search games"
            ></b-form-input>
          </b-list-group-item>
          


        <b-list-group-item
            v-for="(tr, index) in gamesProductSelectOptions"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
          
            <span>{{ tr.gameName }}</span>
            <b-form-checkbox
              :checked="user.restrictedGames.includes(tr._id)"
              class="custom-control-danger"
              name="check-button"
              switch
              @change="blockGame(tr)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="LockIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="UnlockIcon" />
              </span>
            </b-form-checkbox>

          </b-list-group-item>
        </b-list-group>
        

      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BCard,
  BOverlay,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import gamesStoreModule from "@/store/games";
import store from "@/store";



export default {
  components: {
    BButton,
    BModal,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BCard,
    BOverlay,
    BBadge,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    modalShow: false,
    user: {},
    searchQueryProduct: "",
    loadingDataGames: false,
    gamesProductSelect: [],
    productSelectId: "",
    searchGames: "",
    productName: "",
  }),
  watch:{
      modalShow(val){
          if(val){
              // this.user.tokens.map(p => p.loading = false )
              this.$store.dispatch('moduleProducts/getProducts')
          }else{
            this.productSelectId = ""
            this.searchGames = ""
          }
      }
  },
  computed: {
    productsOptions(){
      return  this.$store.getters['moduleProducts/productsActives'].filter((product) => {
        return product.name.toLowerCase().includes(this.searchQueryProduct.toLowerCase())
      })
    },
    gamesProductSelectOptions(){
      return  this.gamesProductSelect.filter((game) => {
        return game.gameName.toLowerCase().includes(this.searchGames.toLowerCase())
      })
    }

  },

  methods: {
    async blockGame({gameId, _id}) {
      const obj = {
        userId: this.user.userId,
        gameId: gameId,
        status: this.user.restrictedGames.includes(_id),
      };
      //false para bloqueo
      try{
          // tr.loading = true
         await this.$store.dispatch("usersModule/blockGame", obj);
         if(!obj.status){
           this.user.restrictedGames.push(_id)
          }else{
            const index = this.user.restrictedGames.findIndex(p => p == _id)
            this.user.restrictedGames.splice(index, 1)
          }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${obj.status ? 'Game successfully unlocked' : 'Game blocked successfully'}`,
            icon: `${obj.status ? 'UnlockIcon' : 'LockIcon'}`,
            variant: `${obj.status ? 'success' : 'success'}`,
          },
        });
      } catch (error) {
        console.log(error);
        // validar si el error es 403
        if (error.response.status == 403) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.response.data.message}`,
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `An error occurred, please contact the administrator`,
            },
          });
        }
        
      }finally{
          // tr.loading = false
      }
    },
    selectProduct(productId, productName){
      this.productSelectId =  productId
      this.productName = productName
      this.getGamesProduct(productId)
    },
     async getGamesProduct(productId) {
      try {
        this.loadingDataGames = true;
        const res = await this.$store.dispatch(
          "gamesModule/getGames",
          productId
        );
        this.gamesProductSelect = res.data.games;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching games list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataGames = false;
      }
    },
  },
   created() {
    // Register module
    if (!store.hasModule("gamesModule"))
      store.registerModule("gamesModule", gamesStoreModule);
  },
  destroyed() {
    if (store.hasModule("gamesModule")) store.unregisterModule("gamesModule");
  },
};
</script>

<style>
</style>